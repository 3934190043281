

// Core

@import "argon-dashboard/custom/functions";
@import "argon-dashboard/custom/variables";
@import "argon-dashboard/custom/mixins";

// bootstrap (4.6.0) components

@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/media";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/print";

// Argon utilities and components

@import "argon-dashboard/custom/reboot";
@import "argon-dashboard/custom/utilities";
@import "argon-dashboard/custom/components";

// Vendor (Plugins)

@import "argon-dashboard/custom/vendors";

// Docs components

@import "argon-dashboard/docs/variables";
@import "argon-dashboard/docs/nav";
@import "argon-dashboard/docs/clipboard-js";
@import "argon-dashboard/docs/component-examples";
@import "argon-dashboard/docs/prism";
@import "argon-dashboard/docs/content";
@import "argon-dashboard/docs/sidebar";
@import "argon-dashboard/docs/footer";

// React Differences
@import "react/react-differences";

@import '../../../node_modules/loaders.css/src/animations/line-scale-pulse-out.scss';
@import '../../../node_modules/loaders.css/src/animations/ball-pulse.scss';

$breakpoint-mdpi: 1440px;
$breakpoint-ldpi: 1280px;
$breakpoint-windows-hdpi: 1366px;
$breakpoint-mob: 780px;
$breakpoint-ipad-landscape: 1180px;
$breakpoint-ipad-pro: 1024px;

.loader-hidden {
  display: none;
}
.loader-active {
  display: block;
}

.bg-dark{
  transition: background-color 400ms !important;
  background-color: #212529;
  -webkit-transition: background-color 400ms linear;
  -ms-transition: background-color 400ms linear;
  transition: background-color 400ms linear;
}

.bg-white{
  transition: background-color 400ms !important;
  background-color: #FFF;
  -webkit-transition: background-color 400ms linear;
  -ms-transition: background-color 400ms linear;
  transition: background-color 400ms linear;
}

.bg-darker{
  transition: background-color 400ms !important;
  background-color: #000;
  -webkit-transition: background-color 400ms linear;
  -ms-transition: background-color 400ms linear;
  transition: background-color 400ms linear;
}

.bg-lighter{
  transition: background-color 400ms !important;
  background-color: #e9ecef;
  -webkit-transition: background-color 400ms linear;
  -ms-transition: background-color 400ms linear;
  transition: background-color 400ms linear;
}

.bg-danger-container{
  transition: background-color 400ms !important;
  background-color: #f8d7da;
  color: #842029;
  border: 1px solid #f5c2c7;
  border-radius: 0.375rem;
  padding: 1px;
  position: relative;
  -webkit-transition: background-color 400ms linear;
  -ms-transition: background-color 400ms linear;
  transition: background-color 400ms linear;
}

.bg-warning-container{
  transition: background-color 400ms !important;
  background-color: #fff3cd;
  color: #664d03;
  border: 1px solid #ffecb5;
  border-radius: 0.375rem;
  padding: 1px;
  position: relative;
  -webkit-transition: background-color 400ms linear;
  -ms-transition: background-color 400ms linear;
  transition: background-color 400ms linear;
}

.bg-info-container{
  transition: background-color 400ms !important;
  background-color: #cff4fc;
  color: #055160;
  border: 1px solid #b6effb;
  border-radius: 0.375rem;
  padding: 1px;
  position: relative;
  -webkit-transition: background-color 400ms linear;
  -ms-transition: background-color 400ms linear;
  transition: background-color 400ms linear;
}

.bg-success-container{
  transition: background-color 400ms !important;
  background-color: #88FF9B;
  color: #055160;
  border: 1px solid #88FF9B;
  border-radius: 0.375rem;
  padding: 1px;
  position: relative;
  -webkit-transition: background-color 400ms linear;
  -ms-transition: background-color 400ms linear;
  transition: background-color 400ms linear;
}

.jazz-box {
  display: flex;
  align-items: center;
  .identicon{
    border-radius: 50%;
  }
  div {
    display: flex;
    align-items: center;

    &:nth-child(2) {
      margin-left: 8px;
    }
  }
  .validator-image{
    img{
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
  }
}

.validator-img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  vertical-align: middle;
}

.search-box {
  .identicon{
    border-radius: 50%;
  }
  .validator-image{
    img{
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
  }
  .div{
    max-width: calc(10rem);
    @media (min-width: $breakpoint-ldpi) {
      max-width: calc(18rem);
    }
  }
  display: flex;
  align-items: center;
}

.text-muted-sm {
  color: #8898aa !important;
  font-size: 11px;
}

/*Custom CSS*/
.navbar-p{
  padding: 0px 12px;
}

.navbar-brand{
  padding-bottom: 0px !important;
}
.navbar-brand-img-sm{
  max-height: 4.5rem !important;
  min-height: 1rem;
  width: 150px;
}
.navbar-brand-img{
  max-height: 5rem !important;
  min-height: 1rem;
  width: 225px;
}
.navbar-hr{
  border: 0;
  border-left: 1px solid gray;
  height: inherit;
  margin: 8px 0px;
}
.width-inherit{
  width: inherit;
}

.font-sm{
  font-size: 0.813rem;
}
.nav-link-light{
  color: #00000080 !important;
}
.nav-link-light:hover{
  color: #000000b3 !important;
}
.nav-link-light.active{
  color: #5e72e4 !important;
}
.nav-link-dark{
  color: white !important;
}
.nav-link-dark:hover{
  color: #ffffffa6 !important;
}
.nav-link-dark.active{
  color: #9DABFD !important;
}

.collapsable-menu{
  height: 60px !important;
}
.goerli-testnet-header{
  padding: 5px;
  color: rgb(94, 114, 228);
}
.mt-22{
  margin-top: 22px;
}
.provider-dropdown{
    display: flex;
    flex-direction: column;
    padding:0px 0px 0px 24px;
    p{
        margin-bottom: 6px;
        color: #fff;
        text-transform: uppercase;
      }
  }
  .epoch-feed-header{
    display: flex;
    align-items: center;
  }

  .data-feed-header{
    display: flex;
    align-items: center;
    .custom-job-dropdown{
      display: flex;
      flex-direction: column;
      padding: 0px 15px 0px 0px;
    }
    .custom-provider-dropdown{
      display: flex;
      flex-direction: column;
      padding: 0px 15px 0px 0px;
      margin-top: -22px;
      &:before {
        content: "PROVIDER: ";
        color: #fff;
        font-size: 14px;
      }
    }
  }

  .custom-header-bg{
    background: linear-gradient(87deg, #5e72e4 0, #40429b 100%) !important;
  }

  .custom-warp-logo{
    min-width: 2.25rem;
    font-size: .9375rem;
    line-height: 1.5rem;
  }

  .table th, .table td{
    border: none !important;
  }
  
  .set-min-height{
    min-height: 800px;
  }

  .min-height-bridge{
    min-height: 360px;
  }

  .bg-dark-input{
    background-color: #212529;
    color: #5e72e4;
  }

  .rzr-loader{
    height: 70%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .full-page-rzr-loader{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeinout 1.5s linear;
  }

  .participant-details-header-loader{
    height: 50vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeinout 1.5s linear;
  }

  @keyframes fadeinout {
    0% { opacity: 0; }
    50% { opacity: 0.4; }
    100% { opacity: 0.8; }
  }

  .table-rzr-loader{
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 120px auto;
  }

  .modal-rzr-loader{
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 20px auto;
  }

  .card-rzr-loader{
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .page-rzr-loader{
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 150px auto;
    height: 100vh;
    @media (max-width: $breakpoint-ipad-landscape) {
      margin: 0px auto;
    }    
  }

  .metamask-connected{
    cursor: pointer;
    padding: 8px;
    border: 1px solid #40429b;
    border-radius: 20px;
    &:hover{
      border: 1px solid;
      border-radius: 20px;
    }
  }

  .participant-toggle{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    cursor: pointer;
    color: #FFF;
    font-size: 30px;
    margin: 4rem 0px 3rem 0px;
    .active-tab{
      border-bottom: 4px solid #5e72e4;
      border-radius: 2px;
      opacity: 1;
    }
  }

  .participant-details-toggle{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    color: #FFF;
    font-size: 30px;
    margin: 1em 0;
    .active-tab{
      border-bottom: 4px solid #5e72e4;
      border-radius: 2px;
      opacity: 1;
    }

    div {
      cursor: pointer;
    }
  }

  

  .tab{
    opacity: 0.65;
  }

  .border-none{
    border: none;
  }

  .account-wallet-modal{
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

.pagination{
  margin-top: 1rem;
}

.dropdown-menu{
  .disabled{
    opacity: 0.7;
  }
  .dropdown-item{
    &:hover{
      background-color: initial;
      transform: scale(1.01);
     }
  }
}

.action-disabled{
  opacity: 0.7;
  cursor: not-allowed !important;
  &:focus{
    background-color: initial !important;
  }
}

  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #212529;
    background-color: #cad8fa;
    border-color: #cad8fa; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(247, 250, 252, 0.5); }

.action-btn-padding{
  padding: 1.2rem 3rem !important;
}
.nav-btn-padding{
  padding: 8px !important;
}
.error-btn{
  border-radius: 25px;
  padding: 8px !important;
  font-size: large !important;
  padding: 10px 25px !important;
  border: none;
}
.error-txt{
  font-size: large;
}

.custom-shadow{
  box-shadow: 0px 4px 6px 0px rgba(50,50,93,0.11) !important;
 }

.action-container {
  position: absolute;
  right: 32px;
  margin-top: -10px;
}

//Might have to undo this (makes actions position sticky depending on screen size)
.table-fixed-last tr th:last-child,
.table-fixed-last td:last-child {
  $breakpoint-mdpi: 1280px;
  @media (min-width: $breakpoint-mdpi) {
    // position: sticky;
    // width: 100px;
    right: 0;
    z-index: 10;
  }
}

// .table-fixed-last-d td:last-child{
//   background-color: rgba(33, 37, 41, 0.2);
//   transition: background-color 400ms !important;
//   -webkit-transition: background-color 400ms linear;
//   -ms-transition: background-color 400ms linear;
//   transition: background-color 400ms linear;
// }


// .table-fixed-last-w td:last-child{
//   background-color: rgba(255, 255, 255, 0.2);
//   transition: background-color 400ms !important;
//   -webkit-transition: background-color 400ms linear;
//   -ms-transition: background-color 400ms linear;
//   transition: background-color 400ms linear;
// }

.Toastify__progress-bar--default {
  background: linear-gradient(87deg, #5e72e4 0, #40429b 100%) !important;
}

// .Toastify__progress-bar--dark {
//   background: linear-gradient(87deg, #5e72e4 0, #40429b 100%) !important;
// }

.stakers-table th, .stakers-table td {
  padding: 0.7rem 0.5rem  !important;
}

.stakers-table th:first-child, .stakers-table td:first-child {
  $breakpoint-mdpi: 1280px;
  @media (min-width: $breakpoint-mdpi) {
    padding: 0.7rem 0rem 0.7rem 1rem  !important;
  }
}

.stakers-table th:last-child, .stakers-table td:last-child {
  
  @media (min-width: $breakpoint-mdpi) {
    padding: 0.7rem 1rem 0.7rem 0rem  !important;
  }
}

.pointer-cursor{
  cursor: pointer;
}
  .custom-ellipsis{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .color-green{
    color: #00E400
  }

  .color-red{
    color: red
  }

  .modal-backdrop {
    opacity: 0.8 !important;
    backdrop-filter: blur(20px);
}

.text-white-custom{
  color: #fff;
}
.text-white-custom:hover, .text-white-custom:focus {
  color: gray;
}

.tooltip-z-index{
  z-index: 1001 !important;
}

.action-tooltip-align{
  vertical-align: 0 !important;
}

.address-container-width{
  max-width: 200px;
}

.address-width{
  max-width: calc(8rem);
  @media (min-width: $breakpoint-ldpi) {
    max-width: calc(18rem);
  }
}
.sm-address-width{
  max-width: calc(3.5rem);
  @media (min-width: $breakpoint-mdpi) {
    max-width: calc(12rem);
  }
}
.flex-mobile{
  flex-basis: auto !important;
}

@media (max-width: 1366px) {
  .navbar-nav .nav-link {
    padding: .625rem 0;
    color: #172b4d; }
  .navbar-nav .dropdown-menu {
    box-shadow: none;
    min-width: auto; }
    .navbar-nav .dropdown-menu .media svg {
      width: 30px; }
  .navbar-collapse {
    width: calc(100% - 1.4rem);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1050;
    margin: .7rem;
    overflow-y: auto;
    height: auto !important;
    opacity: 0; }
    .navbar-collapse .navbar-toggler {
      width: 20px;
      height: 20px;
      position: relative;
      cursor: pointer;
      display: inline-block;
      padding: 0; }
      .navbar-collapse .navbar-toggler span {
        display: block;
        position: absolute;
        width: 100%;
        height: 2px;
        border-radius: 2px;
        opacity: 1;
        background: #5e72e4; }
      .navbar-collapse .navbar-toggler :nth-child(1) {
        transform: rotate(135deg); }
      .navbar-collapse .navbar-toggler :nth-child(2) {
        transform: rotate(-135deg); }
    .navbar-collapse .navbar-collapse-header {
      display: block !important;
      padding-bottom: 1rem;
      margin-bottom: 1rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
    .navbar-collapse .collapse-brand img {
      height: 36px; }
    .navbar-collapse .collapse-close {
      text-align: right; }
  .navbar-collapse.collapsing,
  .navbar-collapse.show {
    padding: 1.5rem;
    border-radius: 0.375rem;
    background: #FFF;
    box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1), 0 15px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1);
    animation: show-navbar-collapse .2s ease forwards; }
  .navbar-collapse.collapsing-out {
    animation: hide-navbar-collapse .2s ease forwards; } }

@keyframes show-navbar-collapse {
  0% {
    opacity: 0;
    transform: scale(0.95);
    transform-origin: 100% 0; }
  100% {
    opacity: 1;
    transform: scale(1); } }

@keyframes hide-navbar-collapse {
  from {
    opacity: 1;
    transform: scale(1);
    transform-origin: 100% 0; }
  to {
    opacity: 0;
    transform: scale(0.95); } }

@media (max-width: 1366px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0 !important;
    padding-left: 0 !important; } }    

// Container
.slick-container {
  width: 100%;
  max-width: 1448px;
  margin-left: auto;
  margin-right: auto;
  @include media-breakpoint-up(md) {
      padding-left: ($main-content-padding-x - 15px + $grid-gutter-width / 2) !important;
      padding-right: ($main-content-padding-x - 15px + $grid-gutter-width / 2) !important;
  }
}
.slick-container-tablet {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
      
  @include media-breakpoint-up(md) {
      padding-left: ($main-content-padding-x - 15px + $grid-gutter-width / 2) !important;
      padding-right: ($main-content-padding-x - 15px + $grid-gutter-width / 2) !important;
  }
}
@media (min-width: 768px) {
  .slick-container-tablet {
    max-width: 768px; } }

@media (min-width: 992px) {
  .slick-container-tablet {
    max-width: 1008px; } }

.slick-margin{
  margin-left: -15px;
  // padding-right: -15px;
}
/* Arrows */
.slick-prev-light,
.slick-next-light
{
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;

    display: block;

    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}
.slick-prev-light:hover,
.slick-prev-light:focus,
.slick-next-light:hover,
.slick-next-light:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}
.slick-prev-light:hover:before,
.slick-prev-light:focus:before,
.slick-next-light:hover:before,
.slick-next-light:focus:before
{
    opacity: 1;
}
.slick-prev-light.slick-disabled:before,
.slick-next-light.slick-disabled:before
{
    opacity: .25;
}

.slick-prev-light:before,
.slick-next-light:before
{
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;

    opacity: .75;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev-light
{
    left: -15px;
}
[dir='rtl'] .slick-prev-light
{
    right: -15px;
    left: auto;
}
.slick-prev-light:before
{
    content: '←';
}
[dir='rtl'] .slick-prev-light:before
{
    content: '→';
}

.slick-next-light
{
    right: -15px;
}
[dir='rtl'] .slick-next-light
{
    right: auto;
    left: -15px;
}
.slick-next-light:before
{
    content: '→';
}
[dir='rtl'] .slick-next-light:before
{
    content: '←';
}

/* Dots */
.slick-dotted.slick-slider
{
    margin-bottom: 30px;
}

.slick-dots-dark
{
    position: absolute;
    bottom: -25px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
}
.slick-dots-dark li
{
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
}
.slick-dots-dark li button
{
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots-dark li button:hover,
.slick-dots-dark li button:focus
{
    outline: none;
}
.slick-dots-dark li button:hover:before,
.slick-dots-dark li button:focus:before
{
    opacity: 1;
}
.slick-dots-dark li button:before
{
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    content: '•';
    text-align: center;

    opacity: .25;
    color: white;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots-dark li.slick-active button:before
{
    opacity: .75;
    color: white;
}


.hash-width{
  max-width: 210px;
}
.modal-hash-width{
  max-width: 445px;
}

.asset-width{
  max-width: 320px;
}

.align-center{
  vertical-align: middle;
}

.multi-card{
  min-width: 175px;
}

.hr-sm {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
  border: 0;
  border-top: 1px solid rgba(202, 209, 215, 1); }
.hr-xs {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
  border: 0;
  border-top: 1px solid rgba(202, 209, 215, 1); }

.border-cards{
    border: 1px solid transparent !important;
    border-color: #cad1d7 !important;
    border-radius: 7px;
}

.chart-legend {
	height: 30px;
	position: absolute;
	padding-left: 8px;
	font-size: 12px;
	background-color: inherit;
	text-align: left;
	z-index: 1000;
	pointer-events: none;
}

.switcher-dark {
	display: flex;
	align-items: center;
	height: 30px;
	margin-top: 8px;
	color: #2196F3;
}

.switcher-dark-item {
	cursor: pointer;
	text-decoration: none;
	display: inline-block;
	padding: 6px 8px;
	font-size: 14px;
	color: #f2f3f5;
	background-color: transparent;
	margin-right: 8px;
	border: none;
	border-radius: 4px;
	outline: none;
}
.switcher-dark-item:hover {
  color: #f2f3f5;
	background-color: #525f7f;
}

.switcher-dark-active-item {
	text-decoration: none;
	cursor: default;
	color: #262b3e;
  background-color: #FFF;
}

.switcher-dark-active-item,
.switcher-dark-active-item:hover {
	background-color: #e1eff9;
}

.switcher {
	display: flex;
	align-items: center;
	height: 30px;
	margin-top: 8px;
	color: #2196F3;
}

.switcher-item {
	cursor: pointer;
	text-decoration: none;
	display: inline-block;
	padding: 6px 8px;
	font-size: 14px;
	color: #212529;
	background-color: transparent;
	margin-right: 8px;
	border: none;
	border-radius: 4px;
	outline: none;
}
.switcher-item:hover {
  color: #f2f3f5;
	background-color: #525f7f;
}

.switcher-active-item {
	text-decoration: none;
	cursor: default;
	color: #262b3e;
  background-color: #cad8fa;
}

.switcher-active-item,
.switcher-active-item:hover {
	background-color: #e1eff9;
}

.consent-modal{
  margin: 8rem auto !important;
  position: relative;
  top: 100px;
  width: 60%;
  font-size: 14px;
  line-height: 1.5715;
  max-width: calc(100vw - 32px);
  padding: 0 0 24px;
  .modal-content{
    width: 60vw;
    border-radius: 0.6125rem !important
  }
  .modal-header{
    padding-bottom: 1.25rem !important;
    align-content: center;
    .modal-title{
      font-weight: 600;
    }
  }
  .modal-body{
    padding: 0rem 1.5rem !important 
  }
  .modal-footer{
    .btn{
      width: 50%;
      margin: auto;
    }
  }
  
}

.seperator-dark{
  border-bottom: 24px solid #000 !important;
}
.seperator-light{
  border-bottom: 24px solid #e9ecef !important;
}

.card-header-custom {
  padding: 1.25rem 1.5rem;
  margin-bottom: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); 
  border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) calc(0.375rem - 1px) calc(0.375rem - 1px) !important; 
}

.custom-border{
  border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0 !important; 
}
.seperator-header-dark{
  border-bottom: 32px solid #000 !important;
}
.seperator-header-light{
  border-bottom: 32px solid #e9ecef !important;
}

.form-control{
  border: none !important;
  &:focus{
    border-color: rgb(202, 209, 215) !important;
  }
}

.modal{
  .form-control{
    color: #000 !important;
  }
}

.form-control-input{
    margin-left: 3px !important;
    padding-left: 7px !important;
 }
.form-control-input-light{
    background-color: #eaecef !important;
    margin-left: 3px !important;
    padding-left: 7px !important;
 }
.w-40{
  width: 40%;
}
.governance-details{
  display: flex;
  flex-direction: column;
  .jazz-box{
    margin-bottom: 1rem;
    .identicon{
      border-radius: 50%;
    }
    a{
      font-size: 13px;
    }
  }
  &__list{
      padding-left: 0rem;
      list-style: none;
      display: flex;
      justify-content: space-between;
      flex: 1 1 auto;
      font-size: 14px;
      text-align: center;
  }
}

.bridge-title{
  text-align: center;
  font-size: 1.25rem;
  text-transform: uppercase;
  font-weight: 600;
}

.bridge-body{
  text-align: left;
}

.slashnums{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  .input-group{
    width: 33% !important;
  }
}

.search-bar{
  width: 40%;
  padding-top: 12.5px; 
  margin: 0px auto 0px 12px;
  @media (max-width: $breakpoint-mob) {
    width: 100%;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin: auto;
  }  
}

.dropdown-select{
  padding-top: 12.5px;
  @media (max-width: $breakpoint-mob) {
    width: 100%;
    padding-left: 1.5rem;
    margin: 10px auto 10px auto;
  } 
}

.border-light{
  border: solid;
  border-color: #cad1d7 !important;
  border-width: 0.12rem !important;
  border-radius: 7px;
}

.border-searchbar{
  border: 1px solid transparent !important;
  border-color: #cad1d7 !important;
  border-radius: 7px;
} 

.border-searchbar:focus-within{
  border-color: #2684FF !important;
  box-shadow: 0 0 0 1px #2684FF !important;
}
.border-navbar{
  border: 0.5px solid transparent !important;
  border-color: #cad1d7 !important;
  border-radius: 7px;
} 

.border-navbar:focus-within{
  border-color: #2684FF !important;
  box-shadow: 0 0 0 0.5px #2684FF !important;
}

.border-error:focus-within{
  border-color: red !important;
  box-shadow: 0 0 0 1px red !important;
}


.border-dark{
  border: solid;
  border-color: #212529 !important;
  border-width: 0.12rem !important;
  border-radius: 7px;
}

.custom-font-size{
  font-size: medium;
}

.last-sync-icon {
  color: #07bc0c;
  font-size: 8px;
  border-radius: 20px;
}
.desync-icon {
  color: red;
  font-size: 8px;
  border-radius: 20px;
}

.staker-details{
  display: flex;
  flex-direction: column;
  .jazz-box{
    margin-bottom: 1rem;
    .identicon{
      border-radius: 50%;
    }
    span{
      font-size: 13px;
    }
  }
  &__list{
    h4{
      font-size: 12px;
      line-height: 1.25rem;
      text-transform: uppercase;
      margin-bottom: 8px;
      letter-spacing: 1.2px;
      font-weight: 500;
    }
    ul{
        padding-left: 0rem;
        list-style: none;
        display: flex;
        justify-content: space-between;
        flex: 1 1 auto;
        font-size: 12px;
        text-align: center;
    }
  }
}

.account-modal{
  .modal-content{
    border-radius: 0.6125rem !important
  }
}

.brand-margin{
  margin-top: -75px;
  // padding-bottom: 42px;
}


.user-action-modal{
  margin: 8rem auto !important;
  .modal-content{
    border-radius: 0.6125rem !important
  }
  .modal-header{
    padding-bottom: 1.25rem !important;
  }
  .modal-body{
    padding: 0rem 1.5rem !important 
  }
  .modal-footer{
    padding-top: 1.25rem !important;
  }
  span{
    font-size: 12px;
  }
}

.user-unstake-modal{
  margin: 4rem auto !important;
  .modal-content{
    border-radius: 0.6125rem !important
  }
  .modal-header{
    padding-bottom: 1.25rem !important;
  }
  .modal-body{
    padding: 0rem 1.5rem !important 
  }
  .modal-footer{
    padding-top: 1.25rem !important;
  }
  span{
    font-size: 12px;
  }
}

.unstake-withdraw-info-modal{
  margin: 8rem auto !important;
  .modal-content{
    border-radius: 0.6125rem !important
  }
  .modal-header{
    padding-bottom: 1.25rem !important;
  }
  .modal-body{
    padding: 0rem 1.5rem !important 
  }
  .modal-footer{
    padding-top: 1.25rem !important;
  }
  span{
    font-size: 12px;
    text-transform: none;
  }
}

.consume-datafeeds-modal{
    margin: 8rem auto !important;
    max-width: 768px;
    .modal-content{
      border-radius: 0.6125rem !important
    }
    .modal-header{
      padding-bottom: 1.25rem !important;
    }
    .modal-body{
      padding: 0rem 1.5rem !important 
    }
    .modal-footer{
      padding-top: 1.25rem !important;
    }
    span{
      font-size: 12px;
      text-transform: none;
    }
  }

.job-action-modal{
  margin: 8rem auto !important;
  .modal-content{
    border-radius: 0.6125rem !important
  }
  .modal-header{
    padding-bottom: 1.25rem !important;
  }
  .modal-body{
    padding: 0rem 1.5rem !important 
  }
  .modal-footer{
    padding-top: 1.25rem !important;
  }
  span{
    font-size: 12px;
  }
}

.participant-details-toggle div[disabled]
{
  cursor: not-allowed !important;
  pointer-events: none;
  opacity: 0.4;
}

.ml-custom{
  margin-left: 8px;
}

.cursor-pointer{
  cursor: pointer;
}

.min-height{
  min-height: 240px;
}

.min-height-null{
  min-height: 200px;
}

.iheight{
  height: inherit;
  width: 100%;
  overflow-x: auto;
}

.summary-card{
  height: 400px;
  position: relative;
  width: 100%;
  overflow-x: auto
}

.pie-chart-container{
  width: 100%;
  height: 242px;
  @media (max-width: $breakpoint-mob) {
    height: 300px;
  }  
}

.summary-card-rechart-container{
  height: 154px !important;
  width: 100% !important;
}

.summary-link{
    color: inherit;
    text-decoration: none;
    background-color: transparent;
} 
.summary-icon{
    color: #5e72e4;
    text-decoration: none;
    background-color: transparent;
} 
.summary-link:hover {
      color: #233dd2;
      text-decoration: none;
}

.summary-h-border{
  border-bottom: 1px solid rgba(202, 209, 215, 0.5);
}

.summary-border{
    border: 1px solid rgba(202, 209, 215, 0.5) !important;
    border-radius: 5px;
}

.stake-chart{
  height: 25rem;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 2rem;
  margin-top: 1rem;
}
.asset-chart{
  height: 21.25rem;
}

.participant-chart{
  height: 13.2rem;
}

.add-token{
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  margin-right:8px;
  cursor: pointer;
  &:hover{
    transform: scale(1.05);
    opacity: 0.9;
  }
}

.add-token-header{
  border-radius: 0.375rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 20px;
  margin-right: 12px;
  cursor: pointer;
  &:hover{
    transform: scale(1.02);
    opacity: 0.9;
  }
}

.dropdown-menu-light {
  .dropdown-item{
    &:hover{
      background-color: #cad1d7;
      color: #000000b3;
      transform: scale(1) !important;
     }
  }
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  text-align: left;
  list-style: none;
  color: #525f7f;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 0.1875rem solid #5e72e4;
  border-bottom-right-radius: 0.35rem;
  border-bottom-left-radius: 0.35rem;
  border-top-right-radius: 0rem;
  border-top-left-radius: 0rem;
  box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1), 0 15px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1);
}

.dropdown-menu-dark {
  .dropdown-item{
    &:hover{
      background-color: #32325d;
      color: #000000b3;
      transform: scale(1) !important;
     }
  }
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  text-align: left;
  list-style: none;
  color: #fff;
  background-color: #212529 !important;
  background-clip: padding-box;
  border-top: 0.1875rem solid #5e72e4;
  border-bottom-right-radius: 0.35rem;
  border-bottom-left-radius: 0.35rem;
  border-top-right-radius: 0rem;
  border-top-left-radius: 0rem;
  box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1), 0 15px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1);
 }

.dropdown-nav{
  padding-bottom: 11px;
  margin-top: 11px !important;
  margin: auto;
}

.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: #5e72e4;
}
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #9DABFD;
}
.searchbar-nav{
  margin-right: 0 !important;
  margin: 0.5rem auto;
  max-width: 550px;
}

.staker-tabs{
  max-width: 750px;
  justify-content: center;
  margin: 0px auto;
}

.w-full{
  width: 100%;
}

.j-content-sb{
  justify-content: space-between;
}

.flex-direction-column{
  flex-direction: column;
}

.transaction-details-container{
  .card{
    margin-bottom: 40px;
  }
  .card-title{
    margin-bottom: 32px;
  }
  .fa-clone{
    margin-bottom: 6px;
    margin-left: 12px;
    cursor: pointer;
  }
  .polygon-tenderly{
    margin-left: 12px;
  }
}

.flex-d-column{
  flex-direction: column;
}


.provider-modal{
  .modal-body{
    border-radius: .35rem;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    .provider-wrapper{
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all .25s ease;
      cursor: pointer;
      .provider-container{
        width: 100%;
        justify-content: space-between;
        padding: 24px 12px;
        align-items: center;
        &:hover{
          transform: scale(1.02);
        }
        .provider-img{
          height: 42px;
          width: auto;
        }
      }
      .provider-activating{
        background: rgba(255, 255, 255, 0.2) !important;
        -webkit-filter: blur(20px);
        filter: blur(0.5px);
        border-radius: 12px;
      }
    }
  }
  .modal-footer{
    border-bottom-right-radius: .35rem !important;
    border-bottom-left-radius: .35rem !important;
    display: flex;
    justify-content: center;
    padding-top: 0px;
    span{
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1400px; } }

.react-select{
  min-width: 300px;
  padding-right: 1.5rem;
  @media (max-width: $breakpoint-mob) {
    min-width: auto;
  }
}

.custom-epoch-dropdown{
  margin: 44px 0px;
  padding: 0px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  .react-autosuggest__input{
    padding: 4px 8px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
    max-width: 180px;
  }
  .react-autosuggest__container {
    input{
      border-radius: 0.375rem;
    }
    box-shadow: 0px 4px 6px 0px rgba(50,50,93,0.11);
  }
  .react-autosuggest__container--open {
    input{
      border-bottom-left-radius: 0rem;
      border-bottom-right-radius: 0rem;
    }
  }
  .react-autosuggest__suggestions-list{
    list-style: none;
    border: none;
    padding-inline-start: 0px;
    position: absolute;
    z-index: 100;
    max-height: 200px;
    width: 180px;
    overflow: scroll;
    border-bottom-left-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
    cursor: pointer;
  }
  .suggestion-content{
    padding: 12px;
    border-bottom: 12px;
  }
  .react-autosuggest__suggestion{
    .suggestion-content{
      border-bottom: 1px solid transparent;
      border-top: 1px solid transparent;
    }
  }
  .react-autosuggest__suggestion--highlighted{
    .suggestion-content{
      border-bottom: 1px solid #5e72e4;
      border-top: 1px solid #5e72e4;
      transform: scale(1.05,1.05);
    }
  }
}

.custom-search-dropdown{
  padding: 0px;
  .react-autosuggest__input{
    padding: 4px 8px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
  }
  .react-autosuggest__container {
    flex: 1 1 auto;
    // width: 1%;
    min-width: 0; 
    margin-bottom: 0;
    input{
      border-top-left-radius: 0.375rem;
      border-bottom-left-radius: 0.375rem;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      position: relative;
    }
    box-shadow: 0px 4px 6px 0px rgba(50,50,93,0.11);
  }
  .react-autosuggest__container--open {
    input{
      border-bottom-left-radius: 0rem;
      border-bottom-right-radius: 0rem;
    }
  }
  .react-autosuggest__suggestions-list{
    list-style: none;
    border: none;
    padding-inline-start: 0px;
    position: absolute;
    z-index: 100;
    max-height: 200px;
    margin-top: 0.1rem;
    width: calc(100% - 57px);
    overflow: scroll;
    border-bottom-left-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
    cursor: pointer;
    backdrop-filter: blur(50px);
    box-shadow: 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%), 0px 11px 15px -7px rgb(0 0 0 / 20%);
  }
  .suggestion-content{
    margin: 8px;
    padding: 12px;
    border-bottom: 12px;
  }
  .react-autosuggest__suggestion{
    .suggestion-content{
      border: 1px solid transparent;
      border-radius: 0.375rem;
    }
  }
  .react-autosuggest__suggestion--highlighted{
    .suggestion-content{
      border: 1px solid #2196F3;
      background: transparent;
      border-radius: 0.375rem;
    }
  }
  .autosuggest-blockies{
    display: flex;
    align-items: center;
    .identicon{
      border-radius: 50%;
      margin-right: 6px;
    }
    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.dark-suggest{
  .react-autosuggest__container--open{
    .react-autosuggest__suggestions-container--open{
      .react-autosuggest__suggestions-list{
        background-color: rgba(2, 2, 2, 0.181) !important;
      }
    }
  }
}


.light-suggest{
  .react-autosuggest__container--open{
    .react-autosuggest__suggestions-container--open{
      .react-autosuggest__suggestions-list{
        background-color: rgba(176, 195, 201, 0.181) !important;
      }
    }
  }
}

.pulse {
  animation: pulse-animation 2s infinite;
}

.sync-pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0px 0px 0px 0px rgba(7, 188, 12, 0.2);
  }
  100% {
    box-shadow: 0px 0px 0px 10px rgba(7, 188, 12, 0);
  }
}

.desync-pulse {
  animation: desync-pulse-animation 2s infinite;
}

@keyframes desync-pulse-animation {
  0% {
    box-shadow: 0px 0px 0px 0px rgba(188, 7, 7, 0.2);
  }
  100% {
    box-shadow: 0px 0px 0px 10px rgba(188, 7, 7, 0);
  }
}

.error-fallback{
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-top: 10rem;
}

.overflow-s{
  overflow: scroll;
}

.step-progress-container{
  width: 80%;
  text-align: center;
  .step-progress{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    width: auto;
    height: 15px;
    color: white;
    cursor: default;
    font-size: 12px
  }
}
.step-progress-tooltip{
  font-size: 14px !important;
}

.unstake-amount-state{
  display: flex;
  flex-direction: column;
  span:nth-child(2){
    padding-top: 8px;
  }
}

.header-action{
  justify-content: flex-end;
  padding-right: 0.7rem;
  margin-bottom: 1.5rem;
}

.action-rzr-percent{
  background: transparent;
  max-width: 72px;
}

.dark-action-modal-rzr{
  color: white;
  &:hover{
    border: 1px solid #5e72e4;
    background: transparent;
    color: #fff;
  }
  &:active{
    border: 1px solid #5e72e4;
  }
}

.light-action-modal-rzr{
  color: #212529;
  border: none !important;
}

.contracts-loader{
  transform: scale(0.5);
}

.pointer-disabled{
  pointer-events: none;
}

.datafeed-create{
  padding: 1.25rem 1.5rem;
}

.card-header-padding{
  padding: 1.25rem 1.5rem;
}

.font-1rem{
  font-size: 1rem !important;
}

.max-h-100{
  max-height: 100%;
}

.summary-tables{
  @media (min-width: $breakpoint-ipad-landscape) {
    .fixed-max-height{
      height: 554px;
    }
  }
}

.footer-crypto-com-list{
  width: inherit;
  height: inherit;
}

.copyright-ticker{
  display: flex;
  align-items: center;
}

.bg-homepage-gradient-light{
  background: linear-gradient(#5e72e4, #e9ecef);
}

.bg-homepage-gradient-dark{
  background: linear-gradient(#5e72e4, #000);
}

.sticky-message-header{
  display: flex;
  padding: 4px;
  justify-content: center;
  text-align: center;
  p{
    font-size: 13px;
    margin: 0px auto;
    font-weight: 500;
  }
  @media (max-width: $breakpoint-mob) {
    padding: 12px 15px;
    font-size: 14px;
  }
}

.sticky-message-dark{
  color: #fff;
  background-color: #1c345d;
}

.single-pagination-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  span{
    padding-right: 24px;
  }
}

.cookie-consent-container{
  display: flex;
  justify-content: space-between;
  padding: 12px;
  border-radius: 7px;
  width: 100%;
  max-width: 480px;
  position: fixed;
  z-index: 999;
  bottom: 40px;
  right: 0px;
  margin: 0px 24px;
  animation: cookie 1.2s ease forwards;
  @media (max-width: $breakpoint-mob) {
    margin: 10px auto;
    max-width: auto;
    bottom: 2px;
    width: 360px;
    left: 0px;
  }
  .action{
    display: flex;
    justify-content: flex-end;
  }
}

.shadow-razor {
  -webkit-box-shadow: 2px 0px 16px 7px rgba(94,114,228,0.2);
  -moz-box-shadow: 2px 0px 16px 7px rgba(94,114,228,0.2);
  box-shadow: 2px 0px 16px 7px rgba(94,114,228,0.2);
}

@keyframes cookie {
  from {bottom: -100px;}
  50% {bottom: -50px}
  to {bottom: 40px;}
}

@media (max-width: $breakpoint-mob) {
  @keyframes cookie {
    from {bottom: -100px;}
    50% {bottom: -50px}
    to {bottom: 0px;}
  }    
}

.privacy-policy-container {
  padding: 24px;
  .table-responsive{
    margin-top: 36px;
  }
  span{
    font-size: 13px;
  }
}

.chart-tooltip-container{
  display: flex;
  flex-direction: column;
  padding: 12px;
  text-align: left;
}

.acl-action-modal{
  margin: 8rem auto !important;
  .modal-content{
    border-radius: 0.6125rem !important
  }
  .modal-header{
    padding-bottom: 1.25rem !important;
  }
  .modal-body{
    padding: 0rem 1.5rem !important;
    font-size: 13px;
  }
  .modal-footer{
    padding-top: 1.25rem !important;
  }
  span{
    font-size: 12px;
  }
}

.verify-address{
  font-size: 13px;
  .address {
    font-family: monospace !important;
    font-size: 16px;
  }
}

.fw-400{
font-weight: 400;
}

.job-collection-label{
  display: flex;
  align-items: center;
  font-size: 15px;
  padding-right: 12px;
  font-weight: bold;
}
.pre-label{
  display: flex;
  align-items: center;
  font-size: 15px;
  padding-right: 8px;
  font-weight: bold;
  margin-bottom: 0px !important;
}
.pre-label-slashnum{
  display: flex;
  align-items: center;
  font-size: 15px;
  padding-right: 4px;
  font-weight: bold;
  margin-bottom: 0px !important;
}

.input-border-radius{
  border-radius: 10px !important;
}

.d-flex-justify-row{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.width-fill-available{
  width: -webkit-fill-available;
}

.desktop-none{
  @media (min-width: $breakpoint-ipad-pro) {
    display: none;
  }    
}

.error-page{
  font-size: xx-large;
}
.error-height{
  height: 50vh
}


.navbar-nav .nav-link {
    @media (max-width: $breakpoint-windows-hdpi) and (min-width: $breakpoint-ldpi){
        padding: 0 0.425rem !important;
    }
}

.font-bold{
    font-weight: 700;
}

.identicon{
    border-radius: 50%;
}

.custom-toggle {
    position: relative;
    display: inline-block;
    width: $custom-toggle-width;
    height: 1.5rem;

    input {
        &:checked {
             + .custom-toggle-slider {
                border: $custom-control-indicator-border-width solid $custom-control-indicator-checked-border-color;

                &:before {
                    background: $custom-toggle-checked-bg;
                    transform: translateX(1.625rem);
                }

                &:after{
                    color:  $custom-control-indicator-checked-border-color !important; 
                    content: attr(data-label-on) !important;
                    right: auto !important;
                    left: 0 !important;
                }
            }
        }
    }
}

.custom-toggle-slider:after {
    color: #ced4da;
    content: attr(data-label-off);
    display: block;
    font-family: inherit;
    font-weight: 600;
    font-size: .75rem;
    line-height: 24px;
    position: absolute;
    right: 0;
    margin: 0 .21667rem;
    top: 0;
    text-align: center;
    min-width: 1.66667rem;
    overflow: hidden;
    transition: all .3s ease
}

.delegation-toggle-container{
    padding: 16px 24px 0px 24px;
}